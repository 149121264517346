.people-charts-tooltip .ant-tooltip-inner {
  background-color: #4f5882 !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  margin-left: -8px;
  padding: 7px 16px !important;
  font: normal 400 1.6rem/1.5 Work Sans, sans-serif;
  color: #fff !important;
}
.people-charts-tooltip .ant-tooltip-arrow {
  display: block !important;
}
.people-charts-tooltip .ant-tooltip-arrow span {
  background-color: #4f5882 !important;
}
