.search-container {
  width: 47px !important;
  height: 46px !important;
  background: #ffffff !important;
  border: 0.5px solid #ebebf9 !important;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  box-sizing: border-box !important;
  border-radius: 10px !important;
  transition: 0.6s width 0.6s, 0.6s height;
  z-index: 999;
}

.search-container.active {
  width: 100% !important;
  height: 268px !important;
  transition: 0.8s width, 1s height 1s;
}

.search-input {
  max-width: 90%;
  height: 44px;
  padding: 0 !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.search-container .search-input input {
  opacity: 0;
  transition: opacity 0.8s linear;
}

.search-container.active .search-input input {
  padding: 0 10px 0 0 !important;
  opacity: 1;
  transition: opacity 0.8s linear;
}

.active {
  display: block;
  width: 100%;
}

.search-icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-container .search-content {
  width: 100%;
  height: calc(100% - 44px) !important;
  max-height: 300px;
  background: rgb(255, 255, 255);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  opacity: 0;
}

.search-container.active .search-content {
  border-top: 1px solid #f0f0f0 !important;

  opacity: 1;
  transition: 0.8s opacity 1s;
}

.search-list-item {
  height: 48px;
  padding: 12px 20px 13px !important;
}

.list-label-collaborator {
  width: 40%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4f5882;
}

.list-label-area {
  width: 45%;
  margin-left: 9px;
  margin-right: 9px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f5882;
}

.list-action-open {
  width: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

/* Customizações no Ant Design */
.ant-input-prefix {
  width: 47px !important;
  height: 44px !important;
  margin-right: 0 !important;
}

.ant-input {
  border-radius: 10px !important;
}
