.panel-container .bar-chart {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.panel-container .bar-chart .container {
  width: 100%;
  height: 8px;
  margin-top: 7px;
  background-color: #dbdff1;
  border-radius: 30px;
  overflow: hidden;
}

.container .bar {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 30px;
  transition: width 1.8s;
}

.panel-container .bar-chart .header {
  width: 100%;
  padding: 0 3px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.panel-container .bar-chart .header .label {
  display: flex;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4f5882;

  border-radius: 10px;
  transform: matrix(1, -0.01, -0.01, 1, 0, 0);
}

.panel-container .bar-chart .header .label > div {
  content: '';
  width: 12px;
  height: 12px;
  margin-right: 9px;
  background-color: blue;
  border-radius: 50%;
}

.panel-container .bar-chart .header .value {
  display: flex;
  align-items: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  text-align: right;

  color: #4f5882;

  border-radius: 10px;
  transform: matrix(1, -0.01, -0.01, 1, 0, 0);
}

.panel-container .bar-chart .header .value > span {
  margin-right: 14px;
}
