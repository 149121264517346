.callendar {
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  text-align: center !important;
  text-transform: capitalize;
  color: #4f5882 !important;
}

@media (max-width: 764px) {
  .callendar {
    font-size: 1.1rem;
  }
}
.callendar .fc-timegrid-axis-cushion {
  display: none;
}
.callendar .fc-col-header-cell-cushion {
  color: #4f5882 !important;
}
.callendar td.fc-timegrid-slot:not(.fc-timegrid-slot-label),
.callendar td.fc-daygrid-day {
  background-color: rgba(255, 220, 40, 0.15) !important;
}

.fc-scroller {
  margin-bottom: 0px !important;
}

.callendar .fc-scroller::-webkit-scrollbar {
  width: 8px;
}

.callendar .fc-scroller::-webkit-scrollbar-track {
  background: #ffffff;
}

.callendar .fc-scroller::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.callendar .fc-scroller::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1.5);
}

.tippy-box[data-theme~='custom'] {
  background-color: #ffffff80;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 2px rgb(85 85 85 / 8%);
  padding: 0.8rem;
}

.tooltipTitleContainer {
  display: flex;
  border-bottom: 2px solid #4f5882;
}

.pointlabel {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 96px;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.tooltipTitle {
  color: #4f5882;
  font-weight: bold;
}

.tooltipEnter {
  color: #4f5882;
  font-weight: bold;
  margin-top: 5px;
}

.tooltipExit {
  color: #4f5882;
  font-weight: bold;
  margin-top: -4px;
}
