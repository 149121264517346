.side-menu {
  max-height: 100vh;
  top: 0;
  left: 0px;

  min-height: 100vh;
  position: fixed;
  z-index: 999; /*makes the bar open above the content*/
  background-color: #2f4cdd;
}

.side-menu,
.menu,
.submenu,
.menu-item,
.ant-menu,
.ant-menu-item {
  color: #fafbff !important;
  background-color: #2f4cdd !important;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  background-color: #4f5882 !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  margin-left: -8px;
  padding: 7px 16px !important;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner:hover {
  background-color: #4f5882f3 !important;
}

.modal-logout div {
  border-radius: 16px;
}

.modal-logout button {
  border-radius: 15px;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  text-align: center;
  height: 40px;
  width: 50%;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #4f5882;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  float: none;
  display: flex;
  justify-content: space-evenly;
}

.modal-logout button.ant-btn {
  background: #e4e8fb;
  color: #4f5882;
  border-color: #e4e8fb;
  border-radius: 8px;
}

.ant-btn-primary {
  color: #f8f8f8 !important;
  background: #2f4cdd !important;
  border-color: #2f4cdd !important;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: white;
}
