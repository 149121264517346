.list-collapse {
  width: 100%;
  background: #fff;
  border: none !important;
}

.list-collapse .panel-collapse {
  left: 0px;
  top: 0px;
  min-height: 54px !important;
  background: #ffffff;
  border-bottom: 0;
  box-sizing: border-box;
}

.list-collapse .ant-collapse-header {
  border-bottom: 1px solid #e4e8fb;
  padding: 12px 0 !important;
}

.list-collapse .ant-collapse-item-active {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e4e8fb;
}
.list-collapse .ant-collapse-item-active .ant-collapse-header {
  margin-bottom: 1.5rem;
}

.list-collapse .ant-collapse-content-box {
  padding: 0 1rem 0.5rem !important;
  max-height: 212px;
  margin-bottom: 1rem;
  overflow: hidden auto;
  touch-action: auto;
  scrollbar-width: thin;
  scrollbar-color: #4f5882 #f8f8f8;
}

.list-collapse .ant-collapse-content-box::-webkit-scrollbar-track {
  background-color: #f8f8f8;
}

.list-collapse .ant-collapse-content-box::-webkit-scrollbar {
  width: 8px;
  background: #f8f8f8;
}

.list-collapse .ant-collapse-content-box::-webkit-scrollbar-thumb {
  background: #4f5882;
  border-radius: 30px;
}

.list-collapse .ant-collapse-item-active .ant-collapse-content-box div:last-child .bar-chart {
  margin: 0 !important;
}

.list-collapse .panel-collapse .panel-collapse-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #4f5882;
}

.list-collapse .panel-collapse .ant-collapse-header > span {
  color: #4f5882 !important;
}

.list-collapse.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  transform: translateY(-50%) rotate(90deg);
}
.list-collapse.ant-collapse-icon-position-right > .ant-collapse-item-active svg {
  transform: rotate(180deg) !important;
}
