.custom-tooltip {
  background-color: #ffffff80;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 2px rgb(85 85 85 / 8%);
  padding: 0.3rem;
}

.tooltipTitle {
  color: #4f5882;
  font: bold 500 1.2rem/1.5 Work Sans, sans-serif;
}
