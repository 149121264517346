.card {
  width: 100%;
  height: 60px;
  max-height: 438px;

  overflow: hidden;
  background: #ffffff;

  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 10px;

  z-index: 999;
}

.card.expanded {
  height: 438px !important;
}

.card-monitor-header {
  display: flex;
  height: 60px;
}

.card .card-monitor-header .card-expandable {
  width: 10px;
  height: 10px;
  margin-left: 16px;
  margin-top: 19px;
  cursor: pointer;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2f4cdd;
}

.card .card-monitor-header .card-title {
  margin-left: 38px;
  margin-top: 19px;

  width: auto;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4f5882;
}

.card .card-monitor-header .card-controll {
  width: 19px;
  height: 19px;
  margin-left: 285px;
  margin-top: 19px;
  cursor: pointer;

  opacity: 0.24;
}

.card .content {
  display: none;
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100% - 60px);
  padding: 0px;
}

.card.expanded .content {
  display: block;
}

.card.expanded .content .list-monitor {
  border-right: none;
  border-left: none;
}

.list-monitor .area-monitored {
  left: 0px;
  top: 0px;
  min-height: 54px !important;

  background: #ffffff;

  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
}

.ant-collapse-header {
  height: 54px !important;
  display: flex;
  align-items: center;
}

.list-monitor .area-quantity {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 23px;
  top: 8px;

  border-radius: 96px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.list-monitor .area-title {
  position: absolute;
  width: auto;
  height: 19px;
  left: 70px;
  top: 18px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #4f5882;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
  padding-bottom: 16px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  position: relative;
}

.occupation-container {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.occupation-qtdy {
  position: absolute;
  height: 19px;
  width: 7px;
  left: 39px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  text-align: center;

  color: #3fc5ff;
}

.occupation-title {
  position: relative;
  height: 16px;
  left: 70px;

  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #4f5882;
}
